.App {
  background-color: #00071E;
  color: white;
  font-family: 'Work Sans', sans-serif;
}

.myNav {
  width: 70%;
  margin: auto;
}

.Landing {
  background-image: url('../public/bg.png');
  text-align: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  
}

.LandingText {
  width: 50%;
  margin: auto;
}


.header-img {
  width: 100%;
  height: auto;
}

.Section {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 70%;
  margin: auto;
}

.myBtn {
  margin: 10px;
  padding: 5px 25px;
}

.project a {
  text-decoration: none;
  color: white;
}

.project-img {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-img:hover .overlay {
  opacity: 1;
}

.projectDescription {
  color: white;
  padding: 20px;
  /* font-size: 14px; */
}

.Contact {
  min-width: 40%;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.contact-icon {
  width: 80%;
  max-width: 50px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.contact-text {
  font-size: 20px;
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: white;
}

.contact-text a {
  color: white;
}

.SubSection {
  margin-top: 30px;
}

.SubSection a {
  text-decoration: underline;
  color: #69F1FF;
}

/* color white, but opacity at 0.7 */
.BackButton {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  display: flex;
  align-items: center;
}

/* hover over BackButton, and opacity 1, and transition */
.BackButton:hover {
  color: rgba(255, 255, 255, 1);
  transition: color 0.3s ease;
}

.highlight {
  background-color: #FFD25E;
  /* background-color: rgba(66, 151, 160, 0.8); */
  color: #00071E;
  
}

.tiktokOverview{
  background-image: url("../public/project-img/tiktok-3.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 75px;
  text-align: center;
  /* font-weight: normal; */
  font-size: 40px;
  margin-bottom: 50px;
}

.creativeOverview {
  background-image: url("../public/project-img/creative-coding-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 75px;
  text-align: center;
  /* font-weight: normal; */
  font-size: 40px;
  margin-bottom: 50px;
}

.photographyOverview {
  background-image: url("../public/project-img/photography-preview-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 75px;
  text-align: center;
  /* font-weight: normal; */
  font-size: 50px;
  margin-bottom: 50px;
}

.projImg {
  width: 60%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.projImg-full {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

}

.ccGif {
  /* height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px; */
  /* margin-left: auto;
  margin-right: auto; */
  position: relative;
}

.ccGif:hover .overlay {
  opacity: 1;
}

.imgCaption {
  font-style: italic;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 14px;
}

.screenshotCaption {
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 14px;
}

footer {
  background-color: #00071E;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  margin: 0px;
}

